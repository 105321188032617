.App {
  text-align: center;
  padding: 20px;
}

h1 {
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.grid-container {
  flex: 1; /* Allows grid to take up remaining space */
}

.legend-container {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: normal;
}

/* Position the alert to overlay the grid */
.alert-container {
  position: fixed;
  top: 30%; /* Adjust based on where you want it to appear */
  left: 50%;
  transform: translateX(-50%);
  width: 100%; /* Optional: Adjust width */
  z-index: 1000; /* Ensure it appears above other content */
  display: flex;
  justify-content: center;
}

/* Style the alert itself */
.alert-container .alert {
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.9); /* Slight transparency for a better overlay effect */
  border: 2px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
